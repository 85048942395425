<div style="margin-top: 3%">
    <div class="section-heading">
        <h2 class="h2_font_size">Stores</h2>
        <div class="hr"></div>
    </div>
</div>


<section id="contact">
    <div class="container">
        <div class="row" id="contactRow">
            <div class="text-center">
                <h4 class="h4_style">
                    Find your nearest 1 Bake Shop store and experience the best bakery products! Our store network spans
                    across Odisha.<br> Whether you're looking for cakes, bakery products or expert advice, our friendly
                    staff are always ready to help.<br> Use our store locator to find your local store's address.<br>
                    Visit us
                    today!
                </h4>

                <ul class="contact-info">
                    <li style="font-family: myFirstFont;font-size: 30px;color: #e91e63;">1 Bake Shop</li><br>
                    <li>Bharti Restaurants Pvt Ltd.</li><br>
                    <li><i class="fa fa-phone"></i>+91 6742580000 &nbsp;&nbsp; <i class="fa fa-envelope"></i><br><a
                            href="mailto:info@1bakeshop.com">info&#64;1bakeshop.com</a>
                    </li>
                </ul>
            </div>

            <div class="row" id="contactRow">
                <div class="col-md-5 col-lg-5 col-xs-12">
                    <div class="form-style" id="contact_form">
                        <form [formGroup]="createSetUpForm">
                            <div class="form-group">
                                <select class="form-control input-field"
                                    (change)="onStateSelect($any($event.target).value)">
                                    <option value="" disabled selected hidden>State</option>
                                    <option *ngFor="let state of stateList" value="{{ state.refNo }}">
                                        {{ state.stateName }}
                                    </option>
                                </select>
                                <select class="form-control input-field"
                                    (change)="onDistrictSelect($any($event.target).value)">
                                    <option value="" disabled selected hidden>District</option>
                                    <option *ngFor="let district of districtList" value="{{ district.id }}">
                                        {{ district.name }}
                                    </option>
                                </select>
                                <select class="form-control input-field"
                                    (change)="onCitySelect($any($event.target).value)">
                                    <option value="" disabled selected hidden>City</option>
                                    <option *ngFor="let city of cityList" value="{{ city.id }}">
                                        {{ city.name }}
                                    </option>
                                </select>
                                <select class="form-control input-field"
                                    (change)="onLocationSelect($any($event.target).value)">
                                    <option value="" disabled selected hidden>Location</option>
                                    <option *ngFor="let loc of locList" value="{{ loc.id }}">
                                        {{ loc.name }}
                                    </option>
                                </select>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="col-md-7 col-xs-7">
                </div>
            </div>
        </div>
    </div>
</section>

<div style="margin-top: -5%">
    <div id="div_style" class="col-md-5 col-xs-12">
        <div style="background-color: antiquewhite" *ngFor="let locDetails of locationDetailsList; let i = index"
            (click)="onPositionClick(locDetails.lat, locDetails.lng)">
            <div class="col-md-6 col-xs-12" style="padding: 3px">
                <div
                    style="background-color: rgb(226, 233, 240);height: 85px;vertical-align: middle !important;padding-top: 4px;">
                    <h6 class="h6_store" style="color: #121111;">
                        {{ locDetails?.locationName }}&nbsp;
                        <span *ngIf="locDetails?.isCouponApplicable" style="font-size: 10px;color: red;">
                            (Accepts Coupon)</span>
                    </h6>
                    <h6 class="h6_store" style="color: #403e3e;">
                        {{ locDetails?.cityName }}
                    </h6>
                    <h6 class="h6_store" style="color: #625e5e;">
                        {{ locDetails?.address }}
                    </h6>
                    <h6 class="h6_store" style="color: #625e5e;">{{ locDetails.pin }}</h6>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-7 col-xs-12" style="height: 500px; background-color: beige">
        <!-- <div id="map" leaflet (leafletMapReady)="onMapReady($event)" [leafletOptions]="options"></div> -->
        <agm-map [latitude]="latitudeMap" [longitude]="longitudeMap" [zoom]="zoom" (mapReady)="mapReady($event)">
            <agm-marker *ngFor="let marker of gpsLati;let i = index" [latitude]="marker" [longitude]="gpsLong[i]"
                [markerDraggable]="newDeliveryDetails" (dragEnd)="markerDragEnd($any($event))">
            </agm-marker>
            <button type="button" [disabled]="!isShowOpenGoogle" id="Logout"
                class="toggle-button controls button open_map" (click)="onSearch()">Open with google</button>
        </agm-map>
        <!-- <h6 style="text-align: center; color: #625e5e;font-size: 12px;cursor: pointer;" (click)="onSearch()">Search with
            google</h6> -->
    </div>

</div>