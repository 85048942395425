import { MapsAPILoader } from "@agm/core";
import { Component, ElementRef, Inject, NgZone, OnInit, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { interval, map, Observable, startWith, Subscription } from "rxjs";
import { APIFactoryEndPoints } from "src/app/common/apifactory-end-points";
import { LocalStorageService } from "src/app/common/LocalStorageService";
import { Constant } from "src/app/shared/models/constant";
import { GoogleCustomer } from "src/app/shared/models/googlecustomer";
import { OrderSummary } from "src/app/shared/models/ordersummary";
import { Product } from "src/app/shared/models/product";
import { WebOrderAreaAndPinSetupDto } from "src/app/shared/models/web-order-area-and-pin-setup-dto";
import { WebUserInfo } from "src/app/shared/models/web-user-info";
import { WebOrderLocationSetup } from "src/app/shared/models/weborderlocationsetup";
import { CommonService } from "src/app/shared/services/CommonService";
import { PinDialogService } from "src/app/shared/services/pin-dialog.service";
import { ProductService } from "src/app/shared/services/product.service";
import { ToastService } from "src/app/shared/services/toastr.service";
import { UserService } from "src/app/shared/services/user.service";
import { getWindow } from "ssr-window";

@Component({
  selector: "app-pin-dialog",
  templateUrl: "./pin-dialog.component.html",
  styleUrls: ["./pin-dialog.component.scss"],
})
export class PinDialogComponent implements OnInit {
  createSetUpForm: UntypedFormGroup;
  pincode: number;
  areaName: string;
  orderSummary: OrderSummary = new OrderSummary();
  locationSetupList: WebOrderLocationSetup[] = [];
  locationSetup: WebOrderLocationSetup = new WebOrderLocationSetup();
  showClickButton: boolean = true;
  showSubmitBtn: boolean = false;
  showToast: boolean = false;
  currentUrl: string;
  url: string;
  webUserInfo: WebUserInfo = new WebUserInfo();
  googleCustomer: GoogleCustomer;
  readonly intervalTimeForSearch = interval(2000);
  areaPinSearch: WebOrderAreaAndPinSetupDto[] = [];
  filteredOptions: Observable<WebOrderAreaAndPinSetupDto[]>;
  myControl = new UntypedFormControl();

  //Subscription
  private onCreateSetUpClickSubscription: Subscription;
  private onLocationDetailsSubscription: Subscription;
  private assetWebOrderSubscription: Subscription;
  private getCompanySubscription: Subscription;
  private saveWebUserInfoSubscription: Subscription;
  private cartSetUpSubscription: Subscription;
  private intervalSubscription: Subscription;
  private onSearchSubscription: Subscription;

  constructor(
    private localStorage: LocalStorageService,
    public dialogRef: MatDialogRef<PinDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public productValue: any,
    private formBuilder: UntypedFormBuilder,
    private apiFactory: APIFactoryEndPoints,
    private toastService: ToastService,
    private commonService: CommonService,
    private productService: ProductService,
    private userService: UserService,
    private navRoute: Router,
    private constant: Constant,
    private pincodeDialogService: PinDialogService,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone
  ) {
    dialogRef.disableClose = false;
  }

  ngOnInit() {
    this.googleCustomer = JSON.parse(this.localStorage.getItem("google_loggedIn_details"));
    this.url = getWindow().location.hostname;
    this.onCreateSetUp();
    console.log(this.productValue);
    this.currentUrl = this.navRoute.url.replace("/", "");
    console.log(this.currentUrl);
    this.getGoogleMap();
  }

  onCreateSetUp() {
    this.createSetUpForm = this.formBuilder.group({
      pincode: ["", [Validators.required, Validators.pattern("^[1-9][0-9]{5}$")],],
    });
  }

  onSearchAreaName(areaName: string) {
    if (areaName.length < 3) {
      this.showToast = false;
      return;
    }
    if (this.intervalSubscription)
      this.intervalSubscription.unsubscribe();
    const source = this.intervalTimeForSearch;
    this.intervalSubscription = source.subscribe(val => {
      this.intervalSubscription.unsubscribe();

      this.onSearchSubscription = this.pincodeDialogService.getWebOrderAreaAndPinByNameSearch(this.apiFactory.getWebOrderAreaAndPin, this.url, areaName).subscribe((data: WebOrderAreaAndPinSetupDto[]) => {
        if (data.length > 0) {
          this.areaPinSearch = data;
          this.showToast = false;

          this.filteredOptions = this.myControl.valueChanges
            .pipe(
              startWith(''),
              map(value => this._filter(value))
            );
        } else {
          this.showToast = true;
          this.showClickButton = true;
          this.filteredOptions = null;
          this.areaName = areaName;
        }
      }
      );
    });
  }

  _filter(value: any): WebOrderAreaAndPinSetupDto[] {
    if (typeof value === 'string') {
      let filterValue: string = value.toLowerCase();
      return this.areaPinSearch.filter((option: WebOrderAreaAndPinSetupDto) =>
        option.areaName.toLowerCase().includes(filterValue) ||
        option.pincode.toString().includes(filterValue) // Convert pincode to string before searching
      );
    }
    return [];
  }

  onAreaSelect(area: WebOrderAreaAndPinSetupDto) {
    this.intervalSubscription.unsubscribe();
    this.myControl.setValue(area.areaName + ", " + area.cityName + ", " + area.pincode);
    this.filteredOptions = null;
    this.onLocationDetailsSubscription = this.pincodeDialogService.getWebOrderLocationDetailsByRefNo(this.apiFactory.getWebOrderLocationDetailsByRefNo, area.setupId, this.url).subscribe((data) => {
      console.log(data);
      if (data) {
        this.showClickButton = false;
        this.showSubmitBtn = true;
        this.showToast = false;
        this.locationSetup = data;

        if (this.locationSetup.address.length > 40) {
          let firstAdd: string = "";
          const adds = this.locationSetup.address.split(",");
          if (adds.length > 0) {
            for (let i = 0; i < adds.length; i++) {
              if (firstAdd.length + adds[i].length < 40) {
                firstAdd += adds[i] + ", ";
              }
            }
          }
          if (firstAdd.length > 0) {
            this.locationSetup.address1 = firstAdd;
            this.locationSetup.address2 = this.locationSetup.address.substring(
              firstAdd.length - 1,
              this.locationSetup.address.length
            );
          }
        } else {
          this.locationSetup.address1 = this.locationSetup.address;
        }
      } else {
        this.showClickButton = true;
        this.showToast = true;
        this.locationSetupList = [];
        this.saveWebUserInfo();
        // this.toastService.showErrorToast('We are not serving to this pincode');
      }
    });
  }

  clearSearch1() {
    this.myControl.setValue('');
    this.filteredOptions = null;
    this.showToast = false;
    this.showClickButton = true;
    this.locationSetup = new WebOrderLocationSetup();
  }

  choosePincode() {
    this.pincode = this.createSetUpForm.get("pincode").value;
    this.showToast = false;

    if (this.pincode.toString().length === 6) {
      this.onLocationDetailsSubscription = this.pincodeDialogService.getLocationDetailsByPincode(this.pincode, this.url).subscribe((data) => {
        console.log(data);
        if (data.length > 0) {
          this.showClickButton = false;
          this.showSubmitBtn = true;
          this.showToast = false;
          this.locationSetupList = data;

          this.locationSetupList.forEach((element) => {
            if (element.address.length > 40) {
              let firstAdd: string = "";
              const adds = element.address.split(",");
              if (adds.length > 0) {
                for (let i = 0; i < adds.length; i++) {
                  if (firstAdd.length + adds[i].length < 40) {
                    firstAdd += adds[i] + ", ";
                  }
                }
              }
              if (firstAdd.length > 0) {
                element.address1 = firstAdd;
                element.address2 = element.address.substring(
                  firstAdd.length - 1,
                  element.address.length
                );
              }
            } else {
              element.address1 = element.address;
            }
          });
        } else {
          this.showClickButton = true;
          this.showToast = true;
          this.locationSetupList = [];
          this.saveWebUserInfo();
          // this.toastService.showErrorToast('We are not serving to this pincode');
        }
      });
    }
  }

  onCreateSetUpClick(locData: WebOrderLocationSetup) {
    console.log(locData);
    if (!locData || !this.latitudeMap || !this.longitudeMap || !this.pincode) {
      this.toastService.showErrorToast("Please select a valid location");
      return;
    }

    // const storedLocationCode = JSON.parse(this.localStorage.getItem("location_code"));
    // if (storedLocationCode === locData.locationCode) {
    //   console.log("Same location code selected, closing dialog.");
    //   this.dialogRef.close();
    //   return;
    // }

    this.storeLocationData(locData);

    this.pincode = this.createSetUpForm.get("pincode").value;
    this.onCreateSetUpClickSubscription = this.productService.checkPincode(this.apiFactory.checkPincode, locData.pincode, this.url)
      .subscribe((isValidPincode) => {
        if (isValidPincode) {
          this.handleValidPincode(locData);
        } else {
          this.toastService.showErrorToast("Invalid Pincode");
          this.createSetUpForm.patchValue({ pincode: "" });
        }
      });
  }

  private storeLocationData(locData: WebOrderLocationSetup) {
    this.localStorage.setItem("location_code", JSON.stringify(locData.locationCode));
    this.localStorage.setItem("state_code", JSON.stringify(locData.stateCode));
    this.localStorage.setItem("company_code", JSON.stringify(locData.companyCode));
    this.localStorage.setItem("parent_location_code", JSON.stringify(locData.parentLocationCode));
    this.localStorage.setItem("delivery_location", JSON.stringify(locData));
  }

  private handleValidPincode(locData: WebOrderLocationSetup) {
    // Remove old pincode & minSelfLife data from localStorage
    this.localStorage.removeItem("pincode_item");
    this.localStorage.removeItem("min_selflife");

    // Update order summary
    this.orderSummary.pincode = locData.pincode;
    this.orderSummary.product = this.productValue;
    this.pincodeDialogService.setOrderSummary(this.orderSummary);
    this.pincodeDialogService.setPincode(locData.pincode);

    // Save new pincode & minSelfLife data to localStorage
    this.localStorage.setItem("delivery_type", JSON.stringify('delivery'));
    this.localStorage.setItem("pincode_item", JSON.stringify(locData.pincode));
    this.localStorage.setItem("min_selflife", JSON.stringify(locData.minSelfLife));
    this.localStorage.setDeliveryDetails({
      pincode: this.pincode,
      latitude: this.latitudeMap,
      longitude: this.longitudeMap
    });


    const cartProducts: Product[] = JSON.parse(this.localStorage.getItem("avct_item")) || [];

    if (cartProducts.length > 0) {
      // Step 1: Move all cart products to wishlist
      // this.productService.addFavouriteProducts(cartProducts);

      // if (this.googleCustomer && this.googleCustomer.email) {
      //   // Step 2: Empty the cart from backend only if user is logged in
      //   this.cartSetUpSubscription = this.userService.emptyProductCart(this.apiFactory.emptyProductCart, this.googleCustomer.email)
      //     .subscribe((data: any) => {
      //       this.toastService.showSuccessToast("Your cart is now empty due to the delivery pin change.");
      //       this.localStorage.removeItem("avct_item"); // Clear cart locally
      //       if (this.constant.isStorePickupRoute(this.currentUrl)) {
      //         window.location.reload();
      //       }
      //       this.dialogRef.close({ result: data });
      //     });
      // } else {
      //   // If user is not logged in, just clear the cart locally
      //   this.toastService.showSuccessToast("Your cart is now empty due to the delivery pin change.");
      //   this.localStorage.removeItem("avct_item"); // Clear cart locally
      //   if (this.constant.isStorePickupRoute(this.currentUrl)) {
      //     window.location.reload();
      //   }
      //   this.dialogRef.close({ result: true });
      // }
      this.dialogRef.close({ result: true });
    } else {
      this.navRoute.navigate([this.currentUrl]);
      this.dialogRef.close({ result: true });
    }
  }

  saveWebUserInfo() {
    let localWebUserInfo: WebUserInfo = JSON.parse(this.localStorage.getItem("web_user_info"));
    localWebUserInfo.searchedPinCodeArea = this.pincode ? this.pincode.toString() : this.areaName;

    this.saveWebUserInfoSubscription = this.commonService.saveWebUserInfo(this.apiFactory.inserWebUserInfo, localWebUserInfo).subscribe((data) => {
      if (data) {
      }
    });
  }

  addToCart(product: Product) {
    this.productService.addToCart(product);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  //For Google Map
  searchInput: string = '';
  newDeliveryDetails: boolean = true;
  latitudeMap: number;
  longitudeMap: number;
  zoom: number = 10;
  addressMap: string;
  private geoCoder;

  @ViewChild("search") public searchElementRef: ElementRef;

  //Map
  getGoogleMap() {
    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder();

      const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          const place = autocomplete.getPlace();
          if (!place.geometry) return;

          this.latitudeMap = place.geometry.location.lat();
          this.longitudeMap = place.geometry.location.lng();
          this.zoom = 12;

          this.getAddress(this.latitudeMap, this.longitudeMap);
        });
      });
    });
  }

  private setCurrentLocation() {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitudeMap = position.coords.latitude;
        this.longitudeMap = position.coords.longitude;
        this.zoom = 12;
        // this.getAddress(this.latitudeMap, this.longitudeMap);
      });
    }
  }

  getAddress(latitude: number, longitude: number) {
    this.toastService.clearAllToast();
    this.geoCoder.geocode({ location: { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === "OK" && results[0]) {
        this.zoom = 12;
        this.addressMap = results[0].formatted_address;

        const addressComponents = results[0].address_components;
        console.log(addressComponents);
        const pincodeComponent = addressComponents.find(c => c.types.includes('postal_code'));
        if (pincodeComponent) {
          this.pincode = pincodeComponent.long_name;
          this.createSetUpForm.patchValue({ pincode: this.pincode });
          this.choosePincode();
        }
      } else {
        this.toastService.showErrorToast("Geocoder failed due to: " + status);
      }
    });
  }

  markerDragEnd(event: any) {
    this.latitudeMap = event.latLng.lat();
    this.longitudeMap = event.latLng.lng();
    this.getAddress(this.latitudeMap, this.longitudeMap);

    this.createSetUpForm.patchValue({
      latitude: this.latitudeMap,
      longitude: this.longitudeMap,
    });
  }

  fetchCurrentLoc() {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitudeMap = position.coords.latitude;
        this.longitudeMap = position.coords.longitude;
        this.zoom = 12;
        this.createSetUpForm.patchValue({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
        this.getAddress(this.latitudeMap, this.longitudeMap);
      });
    }
  }

  clearSearch() {
    this.searchInput = '';
    this.searchElementRef.nativeElement.value = '';
    this.locationSetupList = [];
    this.locationSetup = new WebOrderLocationSetup();
    this.showToast = false;
    this.showClickButton = true;
  }

  onSearchInput(value: string) {
    this.searchInput = value;
  }

}
