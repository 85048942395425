import { Component, ElementRef, Inject, NgZone, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { APIFactoryEndPoints } from 'src/app/common/apifactory-end-points';
import { FileService } from 'src/app/common/File-Service';
import { LocalStorageService } from 'src/app/common/LocalStorageService';
import { Constant } from 'src/app/shared/models/constant';
import { GoogleCustomer } from 'src/app/shared/models/googlecustomer';
import { Product } from 'src/app/shared/models/product';
import { WebOrderLocationSetup } from 'src/app/shared/models/weborderlocationsetup';
import { PinDialogService } from 'src/app/shared/services/pin-dialog.service';
import { ToastService } from 'src/app/shared/services/toastr.service';
import { UserService } from 'src/app/shared/services/user.service';
import { getWindow } from 'ssr-window';
import { PinDialogComponent } from '../pin-dialog/pin-dialog.component';
import { ProductService } from 'src/app/shared/services/product.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { OrderSummary } from 'src/app/shared/models/ordersummary';
import { MapsAPILoader } from '@agm/core';
import { WebUserInfo } from 'src/app/shared/models/web-user-info';
import { CommonService } from 'src/app/shared/services/CommonService';

@Component({
  selector: 'app-store-pickup-dialog',
  templateUrl: './store-pickup-dialog.component.html',
  styleUrls: ['./store-pickup-dialog.component.scss']
})
export class StorePickupDialogComponent implements OnInit {

  selectedOption: string = '';
  storeLocationList: WebOrderLocationSetup[] = [];
  nearestLocationList: WebOrderLocationSetup[] = [];
  storeLocation: WebOrderLocationSetup;
  url: string;
  currentUrl: string;
  googleCustomer: GoogleCustomer;
  availableOptions = ['storePickup'];
  deliveryType: string;
  storeLatitudeMap: number | null = null;
  storeLongitudeMap: number | null = null;
  searchText: string = '';
  filteredLocations: any[] = [];
  showNearestLocation: boolean = false;

  //For Delivery
  createSetUpForm: UntypedFormGroup;
  pincode: number;
  locationSetupList: WebOrderLocationSetup[] = [];
  orderSummary: OrderSummary = new OrderSummary();
  showToast: boolean = false;
  showClickButton: boolean = true;
  showSubmitBtn: boolean = false;
  selectedIndex: number = null;

  //For Google Map
  searchInput: string = '';
  newDeliveryDetails: boolean = true;
  latitudeMap: number;
  longitudeMap: number;
  zoom: number = 10;
  addressMap: string;
  private geoCoder;
  @ViewChild("search") public searchElementRef: ElementRef;
  private googleMapInitialized = false;

  //Subscriptions
  private onDeliveryLocClickSubscription: Subscription;
  private onLocationDetailsSubscription: Subscription;
  private saveWebUserInfoSubscription: Subscription;
  private cartSetUpSubscription: Subscription;
  private getStorePickupSubscription: Subscription;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<StorePickupDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private localStorage: LocalStorageService,
    private apiFactory: APIFactoryEndPoints,
    private toastService: ToastService,
    private productService: ProductService,
    private navRoute: Router,
    private commonService: CommonService,
    private pinDialogService: PinDialogService,
    public dialog: MatDialog,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone
  ) {
    if (dialogData && dialogData.type) {
      this.selectedOption = dialogData.type;
    }
  }

  ngOnInit(): void {
    this.url = getWindow().location.hostname;
    this.currentUrl = this.navRoute.url.replace("/", "");
    console.log(this.currentUrl)
    this.googleCustomer = JSON.parse(this.localStorage.getItem("google_loggedIn_details"));
    this.deliveryType = JSON.parse(this.localStorage.getItem("delivery_type"));
    this.availableOptions = ['storePickup']; // Set available options based on your conditions

    // Check if only one option is available
    if (this.availableOptions.length === 1 && !this.selectedOption) {
      this.selectedOption = this.availableOptions[0];
    }
    // this.storeLocation = JSON.parse(this.localStorage.getItem("pickup_location"));
    this.setCurrentLocationStore();
    // this.getStoreLocationList();
    this.onCreateSetUp();
  }

  ngAfterViewInit(): void {
    if (this.selectedOption === 'delivery') {
      this.initMapIfNeeded();
    }
  }

  initMapIfNeeded(): void {
    if (!this.googleMapInitialized && this.searchElementRef) {
      this.getGoogleMap();
      this.googleMapInitialized = true;
    }
  }

  private setCurrentLocationStore(): void {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // Location permission granted, get coordinates
          this.storeLatitudeMap = position.coords.latitude;
          this.storeLongitudeMap = position.coords.longitude;
          console.log('Latitude:', this.storeLatitudeMap, 'Longitude:', this.storeLongitudeMap);

          // Call API with the fetched location
          this.getStoreLocationList();
        },
        (error) => {
          // Handle errors
          switch (error.code) {
            case error.PERMISSION_DENIED:
              this.toastService.showErrorToast('Location permission denied. Please allow location access in your browser settings.');
              break;
            case error.POSITION_UNAVAILABLE:
              this.toastService.showErrorToast('Location information is unavailable. Please try again later.');
              break;
            case error.TIMEOUT:
              this.toastService.showErrorToast('The request to get user location timed out. Please try again.');
              break;
            default:
              this.toastService.showErrorToast('An unknown error occurred. Please try again.');
              break;
          }

          // Call API with null latitude & longitude (Location disabled case)
          this.storeLatitudeMap = null;
          this.storeLongitudeMap = null;
          this.getStoreLocationList();
        },
        {
          enableHighAccuracy: true,
          timeout: 30000,
          maximumAge: 0
        }
      );
    } else {
      // If browser does not support geolocation
      this.toastService.showErrorToast('Geolocation is not supported by your browser.');

      // Call API with null values
      this.storeLatitudeMap = null;
      this.storeLongitudeMap = null;
      this.getStoreLocationList();
    }
  }

  getStoreLocationList() {
    // this.storeLocationList = this.fileService.getFileData("locationWithLongLat");
    this.getStorePickupSubscription = this.pinDialogService.getStoreLocationList(this.apiFactory.getActiveStorePickupLocationByUrl,
      this.url, this.storeLatitudeMap != null ? this.storeLatitudeMap.toString() : null, this.storeLongitudeMap != null ? this.storeLongitudeMap.toString() : null)
      .subscribe(data => {
        console.log(data);
        if (data.length > 0) {
          this.storeLocationList = data;
          this.nearestLocationList = data.length > 0 ? data.slice(0, 5) : [];
          this.filteredLocations = this.storeLocationList;
        } else {
          this.toastService.showErrorToast('No store for pickup');
        }
      });
  }

  getDistanceInKm(distanceInMeters: any): string {
    if (!distanceInMeters || isNaN(Number(distanceInMeters))) {
      return 'Distance not available';  // Show a friendly message
    }

    const distanceInKm = Number(distanceInMeters) / 1000; // Convert meters to km

    if (distanceInKm <= 1.75) {
      return 'about 1 km';
    } else if (distanceInKm <= 2.75) {
      return 'about 2 km';
    } else if (distanceInKm <= 3.75) {
      return 'about 3 km';
    } else if (distanceInKm <= 4.75) {
      return 'about 4 km';
    } else if (distanceInKm <= 5.75) {
      return 'about 5 km';
    } else {
      return 'more than 5 km';
    }
  }

  onOptionChange(option: string): void {
    this.selectedOption = option;

    if (option === 'delivery') {
      this.storeLocation = null;
      setTimeout(() => this.initMapIfNeeded());
    } else {
      this.clearSearch();
      this.googleMapInitialized = false;
    }
  }

  toggleNearestLocation() {
    this.showNearestLocation = !this.showNearestLocation; // Toggle state
    this.storeLocation = null;
  }

  onLocationSelect(storeLocation: WebOrderLocationSetup) {
    console.log(storeLocation)
    this.storeLocation = storeLocation;
  }

  filterLocations(event: Event) {
    event.stopPropagation(); // Prevents dropdown from closing
    const searchLower = this.searchText.toLowerCase();
    const allLocations = this.showNearestLocation ? this.nearestLocationList : this.storeLocationList;

    this.filteredLocations = allLocations.filter(loc =>
      loc.locationName.toLowerCase().includes(searchLower) ||
      loc.cityName.toLowerCase().includes(searchLower)
    );
  }

  onContinueClick(selectedOption: string) {
    if (selectedOption == 'storePickup') {
      if (!this.storeLocation) {
        this.toastService.showErrorToast('Select Location');
        return;
      }

      this.localStorage.setItem("delivery_type", JSON.stringify(selectedOption));
      this.localStorage.setItem("location_code", JSON.stringify(this.storeLocation.locationCode));
      this.localStorage.setItem("company_code", JSON.stringify(this.storeLocation.companyCode));
      this.localStorage.setItem("state_code", JSON.stringify(this.storeLocation.stateCode));
      this.localStorage.setItem("min_selflife", JSON.stringify(this.storeLocation.minSelfLife));
      this.localStorage.setItem("parent_location_code", JSON.stringify(this.storeLocation.parentLocationCode));
      this.localStorage.setItem("pickup_location", JSON.stringify(this.storeLocation));

      this.clearSearch();
      this.dialogRef.close('storePickup');
    } else if (selectedOption == 'delivery') {
      this.onDeliveryLocClick(this.locationSetupList[this.selectedIndex]);
      // this.dialogRef.close('delivery');
      this.storeLocation = null;
    } else {
      this.toastService.showSuccessToast("Pickup or Delivery");
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onCreateSetUp() {
    this.createSetUpForm = this.formBuilder.group({
      pincode: ["", [Validators.required, Validators.pattern("^[1-9][0-9]{5}$")],],
    });
  }

  choosePincode() {
    this.pincode = this.createSetUpForm.get("pincode").value;
    this.showToast = false;

    if (this.pincode.toString().length === 6) {
      this.onLocationDetailsSubscription = this.pinDialogService.getLocationDetailsByPincode(this.pincode, this.url).subscribe((data) => {
        console.log(data);
        if (data.length > 0) {
          this.showClickButton = false;
          this.showSubmitBtn = true;
          this.showToast = false;
          this.locationSetupList = data;

          this.locationSetupList.forEach((element) => {
            if (element.address.length > 40) {
              let firstAdd: string = "";
              const adds = element.address.split(",");
              if (adds.length > 0) {
                for (let i = 0; i < adds.length; i++) {
                  if (firstAdd.length + adds[i].length < 40) {
                    firstAdd += adds[i] + ", ";
                  }
                }
              }
              if (firstAdd.length > 0) {
                element.address1 = firstAdd;
                element.address2 = element.address.substring(
                  firstAdd.length - 1,
                  element.address.length
                );
              }
            } else {
              element.address1 = element.address;
            }
          });
          if (this.locationSetupList.length == 1) {
            this.onSelectLocation(0);
          }
        } else {
          this.showClickButton = true;
          this.showToast = true;
          this.locationSetupList = [];
          this.selectedIndex = null;
          this.saveWebUserInfo();
        }
      });
    }
  }

  onSelectLocation(index: number) {
    this.selectedIndex = index;
  }

  onDeliveryLocClick(locData: WebOrderLocationSetup) {
    console.log(locData);
    if (!locData || !this.latitudeMap || !this.longitudeMap || !this.pincode) {
      this.toastService.showErrorToast("Please select a valid location");
      return;
    }

    this.storeLocationData(locData);

    this.pincode = this.createSetUpForm.get("pincode").value;
    this.onDeliveryLocClickSubscription = this.productService.checkPincode(this.apiFactory.checkPincode, locData.pincode, this.url)
      .subscribe((isValidPincode) => {
        if (isValidPincode) {
          this.handleValidPincode(locData);
        } else {
          this.toastService.showErrorToast("Invalid Pincode");
          this.createSetUpForm.patchValue({ pincode: "" });
        }
      });
  }

  private storeLocationData(locData: WebOrderLocationSetup) {
    this.localStorage.setItem("location_code", JSON.stringify(locData.locationCode));
    this.localStorage.setItem("state_code", JSON.stringify(locData.stateCode));
    this.localStorage.setItem("company_code", JSON.stringify(locData.companyCode));
    this.localStorage.setItem("parent_location_code", JSON.stringify(locData.parentLocationCode));
    this.localStorage.setItem("delivery_location", JSON.stringify(locData));
  }

  private handleValidPincode(locData: WebOrderLocationSetup) {
    // Remove old pincode & minSelfLife data from localStorage
    this.localStorage.removeItem("pincode_item");
    this.localStorage.removeItem("min_selflife");

    // Update order summary
    this.orderSummary.pincode = locData.pincode;
    this.orderSummary.product = this.dialogData?.productValue;
    this.pinDialogService.setOrderSummary(this.orderSummary);
    this.pinDialogService.setPincode(locData.pincode);

    // Save new pincode & minSelfLife data to localStorage
    this.localStorage.setItem("delivery_type", JSON.stringify('delivery'));
    this.localStorage.setItem("pincode_item", JSON.stringify(locData.pincode));
    this.localStorage.setItem("min_selflife", JSON.stringify(locData.minSelfLife));
    this.localStorage.setDeliveryDetails({
      pincode: this.pincode,
      latitude: this.latitudeMap,
      longitude: this.longitudeMap
    });


    const cartProducts: Product[] = JSON.parse(this.localStorage.getItem("avct_item")) || [];

    if (cartProducts.length > 0) {
      this.dialogRef.close('delivery');
    } else {
      this.navRoute.navigate([this.currentUrl]);
      this.dialogRef.close('delivery');
    }
  }

  //Map
  getGoogleMap() {
    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocationPin();
      this.geoCoder = new google.maps.Geocoder();

      const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          const place = autocomplete.getPlace();
          if (!place.geometry) return;

          this.latitudeMap = place.geometry.location.lat();
          this.longitudeMap = place.geometry.location.lng();
          this.zoom = 12;

          this.getAddress(this.latitudeMap, this.longitudeMap);
        });
      });
    });
  }

  private setCurrentLocationPin() {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitudeMap = position.coords.latitude;
        this.longitudeMap = position.coords.longitude;
        this.zoom = 12;
        // this.getAddress(this.latitudeMap, this.longitudeMap);
      });
    }
  }

  getAddress(latitude: number, longitude: number) {
    this.toastService.clearAllToast();
    this.geoCoder.geocode({ location: { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === "OK" && results[0]) {
        this.zoom = 12;
        this.addressMap = results[0].formatted_address;

        const addressComponents = results[0].address_components;
        console.log(addressComponents);
        const pincodeComponent = addressComponents.find(c => c.types.includes('postal_code'));
        console.log(pincodeComponent);
        if (pincodeComponent) {
          this.pincode = pincodeComponent.long_name;
          this.createSetUpForm.patchValue({ pincode: this.pincode });
          this.choosePincode();
        }else{
          this.toastService.showErrorToast("Pincode not found.");
          this.clearSearch();
        }
      } else {
        this.toastService.showErrorToast("Geocoder failed due to: " + status);
      }
    });
  }

  markerDragEnd(event: any) {
    this.latitudeMap = event.latLng.lat();
    this.longitudeMap = event.latLng.lng();
    this.getAddress(this.latitudeMap, this.longitudeMap);

    this.createSetUpForm.patchValue({
      latitude: this.latitudeMap,
      longitude: this.longitudeMap,
    });
  }

  fetchCurrentLoc() {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitudeMap = position.coords.latitude;
        this.longitudeMap = position.coords.longitude;
        this.zoom = 12;
        this.createSetUpForm.patchValue({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
        this.getAddress(this.latitudeMap, this.longitudeMap);
      });
    }
  }

  clearSearch() {
    this.searchInput = '';
    if (this.searchElementRef?.nativeElement) {
      this.searchElementRef.nativeElement.value = '';
    }
    this.locationSetupList = [];
    this.showToast = false;
    this.showClickButton = true;
    this.googleMapInitialized = false;
  }

  onSearchInput(value: string) {
    this.searchInput = value;
  }

  saveWebUserInfo() {
    let localWebUserInfo: WebUserInfo = JSON.parse(this.localStorage.getItem("web_user_info"));
    localWebUserInfo.searchedPinCodeArea = this.pincode.toString();

    this.saveWebUserInfoSubscription = this.commonService.saveWebUserInfo(this.apiFactory.inserWebUserInfo, localWebUserInfo).subscribe((data) => {
      if (data) {
      }
    });
  }

}
