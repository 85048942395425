<div>
    <div class="section-heading">
        <h5>Select your preferred option</h5>
    </div>
    <div mat-dialog-content>
        <div style="margin-top: 10px;">
            <div class="delivery-selection">
                <button class="delivery-tab" [class.active]="selectedOption === 'storePickup'"
                    (click)="onOptionChange('storePickup')">
                    <img [src]="selectedOption === 'storePickup' ? 'assets/img/pickup_white.png' : 'assets/img/pickup_pink.png'"
                        class="delivery-icon" />
                    Pickup
                    <span *ngIf="selectedOption === 'storePickup'" class="selected-tick">✔</span>
                    <span *ngIf="selectedOption !== 'storePickup'" class="selected-tick"></span>
                </button>

                <button class="delivery-tab" [class.active]="selectedOption === 'delivery'"
                    (click)="onOptionChange('delivery')">
                    <img [src]="selectedOption === 'delivery' ? 'assets/img/delivery_white.svg' : 'assets/img/delivery_pink.svg'"
                        class="delivery-icon" />
                    Delivery
                    <span *ngIf="selectedOption === 'delivery'" class="selected-tick">✔</span>
                    <span *ngIf="selectedOption !== 'delivery'" class="selected-tick"></span>
                </button>
            </div>

            <div *ngIf="selectedOption === 'storePickup'" style="width: 100%; margin-top: 10px;">
                <!-- Location Selection -->
                <mat-card class="contain_card" fxFlex="100" style="padding-left: 9px !important; margin-top: 10px;">
                    <mat-form-field class="selectOption">
                        <mat-select [placeholder]="showNearestLocation ? 'Select Nearest Location' : 'Select Location'"
                            (selectionChange)="onLocationSelect($event.value)" [value]="storeLocation"
                            panelClass="custom-panel">

                            <!-- Search Box (Properly Positioned & Non-Selectable) -->
                            <mat-option *ngIf="true" class="search-option" [disableRipple]="true" [disableClose]="true">
                                <mat-form-field class="search-field">
                                    <input matInput type="text" [(ngModel)]="searchText"
                                        placeholder="Search location..." (keyup)="filterLocations($event)"
                                        (click)="$event.stopPropagation()" />
                                </mat-form-field>
                            </mat-option>

                            <!-- Filtered Locations -->
                            <mat-option *ngFor="let loc of filteredLocations" [value]="loc">
                                <div class="location-name">{{ loc.locationName }} ({{ loc.cityName }})</div>
                                <div *ngIf="getDistanceInKm(loc.distanceInMeters) !== 'Distance not available'"
                                    class="distance-text">
                                    {{ getDistanceInKm(loc.distanceInMeters) }}
                                </div>
                            </mat-option>

                        </mat-select>
                    </mat-form-field>
                </mat-card>

                <!-- Display Selected Location Address -->
                <div *ngIf="storeLocation" class="store-address">
                    <h3>Selected Address: {{storeLocation.locationName}}</h3>
                    <p>{{storeLocation.address}}</p>
                    <p><small>{{ getDistanceInKm(storeLocation.distanceInMeters) }}</small></p>
                </div>
            </div>

            <div *ngIf="selectedOption === 'delivery'" style="width: 100%;">
                <form [formGroup]="createSetUpForm">
                    <div style="display: flex;justify-content: center;">
                        <div style="width: 100%;">
                            <div style="padding-bottom: 10px; display: flex; align-items: center; position: relative;">
                                <input type="text" class="form-control" placeholder="Search delivery location"
                                    autocorrect="off" autocapitalize="off" spellcheck="off" #search
                                    [disabled]="!newDeliveryDetails" (input)="onSearchInput(search.value)"
                                    (keydown.enter)="$event.preventDefault()" style="flex: 1;" />

                                <!-- Clear icon -->
                                <button mat-icon-button *ngIf="searchInput" (click)="clearSearch()" aria-label="Clear"
                                    style="width: 20px;">
                                    <mat-icon class="fetch_icon">close</mat-icon>
                                </button>

                                <!-- GPS icon -->
                                <button mat-icon-button (click)="fetchCurrentLoc()" aria-label="Current location"
                                    style="width: 30px;">
                                    <mat-icon class="fetch_icon"
                                        matTooltip="Fetch current location">my_location</mat-icon>
                                </button>
                            </div>

                            <agm-map [latitude]="latitudeMap" [longitude]="longitudeMap" [zoom]="zoom">
                                <agm-marker [latitude]="latitudeMap" [longitude]="longitudeMap"
                                    [markerDraggable]="newDeliveryDetails" (dragEnd)="markerDragEnd($event)">
                                </agm-marker>
                            </agm-map>
                        </div>
                    </div>

                    <div *ngIf="showToast" style="margin-top: 10px;">
                        <p class="h7">
                            Delivery to this pincode {{ pincode }} is currently not available
                        </p>
                    </div>

                    <div class="section-heading preferred_location" *ngIf="!showClickButton">
                        <h5 style="margin-top: 15px">Select Preferred Location</h5>
                    </div>
                    <div style="max-height: 200px;overflow: auto;margin: 0 -24px !important;padding-top: 10px;"
                        *ngIf="!showClickButton">
                        <div *ngFor="let locationSetup of locationSetupList; let i = index" class="col-lg-12"
                            style="display: flex; height: 80px; align-items: center;margin-bottom: 10px;">
                            <div class="location_div" [class.selected]="i === selectedIndex"
                                (click)="onSelectLocation(i)">
                                <div class="location-name-pin">{{ locationSetup.locationName }}</div>
                                <div *ngIf="locationSetup.address1" class="address1">{{ locationSetup.address1 }}</div>
                                <div *ngIf="locationSetup.address2" class="address2">{{ locationSetup.address2 }}</div>
                                <div *ngIf="locationSetup.pincode" class="pincode">{{ locationSetup.pincode }}</div>
                                <div class="selected_div" *ngIf="i === selectedIndex">
                                    <mat-icon class="check_icon">check_circle</mat-icon>
                                </div>
                            </div>
                        </div>

                    </div>

                </form>
            </div>
        </div>
    </div>
    <div mat-dialog-actions class="dialog-actions">
        <button mat-button mat-dialog-close class="cancel-button">Cancel</button>
        <button mat-button (click)="onContinueClick(selectedOption)" class="continue-button">Continue</button>
    </div>
</div>