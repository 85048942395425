<div style="margin-top: 3%">
  <div class="section-heading">
    <h2 class="h2_font_size">Payment History</h2>
    <div class="hr"></div>
  </div>
</div>

<div *ngIf="dataSource?.data?.length > 0; else noData" class="responsive-grid">
  <div *ngFor="let element of dataSource.data" class="transaction-card">
    <div class="field">
      <label>Date & Time:</label>
      <span>{{ element.dateAndTime | date: 'yyyy-MM-dd HH:mm:ss' }}</span>
    </div>
    <div class="field">
      <label>Amount:</label>
      <span>{{ element.amount | currency: 'INR' }}</span>
    </div>
    <div class="field">
      <label>Transaction ID:</label>
      <span>{{ element.transactionId }}</span>
    </div>
    <div class="field">
      <label>Bank Trans ID:</label>
      <span *ngIf="element.bankTransactionId && element.bankTransactionId !== 'null'">
        {{ element.bankTransactionId }}
      </span>
      <span *ngIf="!element.bankTransactionId || element.bankTransactionId === 'null'">
        Not Available
      </span>
    </div>
    <div class="field">
      <label>Status:</label>
      <span [ngClass]="getStatusClass(element.transactionsStatus)">
        {{ element.transactionsStatus }}
      </span>
    </div>
  </div>
</div>

<ng-template #noData>
  <p style="text-align: center; padding: 20px;">No transactions found.</p>
</ng-template>